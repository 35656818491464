import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [   
  {
    path: 'members',
    canActivate: [AuthGuard],
    loadChildren: () => import('./residential/residential.module').then(m => m.ResidentialPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },{
    path: 'members/conference-list',
    loadChildren: () => import('./conference-list/conference-list.module').then( m => m.ConferenceListPageModule)
  },
  {
    path: 'members/professionals-list/calendar',
    loadChildren: () => import('./calendar/calendar.module').then( m => m.CalendarPageModule)
  },
  {
    path: 'members/tickets',
    loadChildren: () => import('./help/help.module').then( m => m.HelpPageModule)
  },
  {
    path: 'members/tickets/tickets-add',
    loadChildren: () => import('./help-add/help-add.module').then( m => m.HelpAddPageModule)
  },
  {
    path: 'members/conversation',
    loadChildren: () => import('./conversation/conversation.module').then( m => m.ConversationPageModule)
  },
  {
    path: 'members/bulletin',
    loadChildren: () => import('./bulletin/bulletin.module').then( m => m.BulletinPageModule)
  },
  {
    path: 'members/bulletin/bulletin-view',
    loadChildren: () => import('./bulletin-view/bulletin-view.module').then( m => m.BulletinViewPageModule)
  },
  {
    path: 'members/challenge',
    loadChildren: () => import('./challenge/challenge.module').then( m => m.ChallengePageModule)
  },
  {
    path: 'members/profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'home/unit',
    loadChildren: () => import('./unit/unit.module').then( m => m.UnitPageModule)
  },    
  {
    path: 'members/residents',
    loadChildren: () => import('./residents/residents.module').then( m => m.ResidentsPageModule)
  },
  {
    path: 'members/residents-add',
    loadChildren: () => import('./residents-add/residents-add.module').then( m => m.ResidentsAddPageModule)
  },
  {
    path: 'members/administration',
    loadChildren: () => import('./administration/administration.module').then( m => m.AdministrationPageModule)
  },
  {
    path: 'members/residents-edit',
    loadChildren: () => import('./residents-edit/residents-edit.module').then( m => m.ResidentsEditPageModule)
  },
  {
    path: 'members/reservation',
    loadChildren: () => import('./reservation/reservation.module').then( m => m.ReservationPageModule)
  },
  {
    path: 'members/reservation/reservation-add',
    loadChildren: () => import('./reservation-add/reservation-add.module').then( m => m.ReservationAddPageModule)
  },
  {
    path: 'members/reservation/reservation-view',
    loadChildren: () => import('./reservation-view/reservation-view.module').then( m => m.ReservationViewPageModule)
  },
  {
    path: 'members/reservation/reservation-guest',
    loadChildren: () => import('./reservation-guest/reservation-guest.module').then( m => m.ReservationGuestPageModule)
  },
  {
    path: 'members/reservation/reservation-guest/reservation-guest-add',
    loadChildren: () => import('./reservation-guest-add/reservation-guest-add.module').then( m => m.ReservationGuestAddPageModule)
  },
  {
    path: 'qr-scan',
    loadChildren: () => import('./qr-scan/qr-scan.module').then( m => m.QrScanPageModule)
  },
  {
    path: 'members/reservation/reservation-guest/reservation-guest-add-massive',
    loadChildren: () => import('./reservation-guest-add-massive/reservation-guest-add-massive.module').then( m => m.ReservationGuestAddMassivePageModule)
  },
  {
    path: 'members/reservation/reservation-guest/reservation-guest',
    loadChildren: () => import('./reservation-guest/reservation-guest.module').then( m => m.ReservationGuestPageModule)
  },
  {
    path: 'members/myAccount',
    loadChildren: () => import('./myAccount/myAccount.module').then( m => m.MyAccountPageModule)
  },
  {
    path: 'members/reportPayment',
    loadChildren: () => import('./reportPayment/reportPayment.module').then( m => m.ReportPaymentPageModule)
  },
  {
    path: 'members/reportPayment/reportPayment-add',
    loadChildren: () => import('./reportPayment-add/reportPayment-add.module').then( m => m.ReportPaymentAddPageModule)
  },
  {
    path: 'residents-search',
    loadChildren: () => import('./residents-search/residents-search.module').then( m => m.ResidentsSearchPageModule)
  },
  {
    path: 'members/chat',
    loadChildren: () => import('./chat/chat.module').then( m => m.ChatPageModule)
  },
  {
    path: 'members/access-guest',
    loadChildren: () => import('./access-guest/access-guest.module').then( m => m.AccessGuestPageModule)
  },
  {
    path: 'members/access-guest/access-guest-view',
    loadChildren: () => import('./access-guest-view/access-guest-view.module').then( m => m.AccessGuestViewPageModule)
  },
  {
    path: 'members/tab1',
    loadChildren: () => import('./tab1/tab1.module').then( m => m.Tab1PageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then( m => m.TabsPageModule)
  },
  {
  path: 'members/home',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'members/all-notifications',
    loadChildren: () => import('./tab1/tab1.module').then( m => m.Tab1PageModule)
  },
  {
    path: 'members/notification',
    loadChildren: () => import('./notification/notification.module').then( m => m.NotificationPageModule)
  },
  {
    path: 'members/residentail',
    loadChildren: () => import('./residential/residential.module').then( m => m.ResidentialPageModule)
  },
  {
    path: 'members/access-guest/access-guest-add',
    loadChildren: () => import('./access-guest-add/access-guest-add.module').then( m => m.AccessGuestAddPageModule)
  },{
    path: 'members/event',
    loadChildren: () => import('./event/event.module').then( m => m.EventPageModule)
  },{
    path: 'members/event-view',
    loadChildren: () => import('./event-view/event-view.module').then( m => m.EventViewPageModule)
  },{
    path: 'members/event-guest',
    loadChildren: () => import('./event-guest/event-guest.module').then( m => m.EventGuestPageModule)
  },{
    path: 'members/event-guest-add',
    loadChildren: () => import('./event-guest-add/event-guest-add.module').then( m => m.EventGuestAddPageModule)
  },{
    path: 'members/modal-manage-user-content',
    loadChildren: () => import('./modalManageUserContent/modalManageUserContent.module').then( m => m.ModalManageUserContentPageModule)
  },{
    path: 'members/modal-delete-user',
    loadChildren: () => import('./modalDeleteUser/modalDeleteUser.module').then( m => m.ModalDeleteUserPageModule)
  },{
    path: 'members/modal-select-user',
    loadChildren: () => import('./modalSelectUser/modalSelectUser.module').then( m => m.ModalSelectUserPageModule)
  },{
    path: 'members/modal-select-contact',
    loadChildren: () => import('./modalSelectContact/modalSelectContact.module').then( m => m.ModalSelectContactPageModule)
  },{
    path: 'members/modal-attach',
    loadChildren: () => import('./modalAttach/modalAttach.module').then( m => m.ModalAttachPageModule)
  }
  ,{
    path: 'members/reportPaymentBill',
    loadChildren: () => import('./reportPaymentBill/reportPaymentBill.module').then( m => m.ReportPaymentBillPageModule)
  },{
    path: 'members/contactBook',
    loadChildren: () => import('./contact-book/contact-book.module').then( m => m.ContactBookPageModule)
  },{
    path: 'members/contactBookAdd',
    loadChildren: () => import('./contact-book-add/contact-book-add.module').then( m => m.ContactBookAddPageModule)
  },
  {
    path: 'members/contactBookView',
    loadChildren: () => import('./contact-book-view/contact-book-view.module').then( m => m.ContactBookViewPageModule)
  },
  {
    path: 'members/access-guest/access-guest-edit',
    loadChildren: () => import('./access-guest-edit/access-guest-edit.module').then( m => m.AccessGuestEditPageModule)
  }



];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
