import { NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IonicStorageModule } from '@ionic/storage';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx'; // Importar InAppBrowser
import { HttpClientModule } from '@angular/common/http';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import {ModalManageUserContentPage} from "./modalManageUserContent/modalManageUserContent.page"
import {ModalDeleteUserPage} from "./modalDeleteUser/modalDeleteUser.page"

/*{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }*/
@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,HttpClientModule,IonicStorageModule.forRoot(), ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })],
  providers: [StatusBar,SplashScreen,{ provide: LocationStrategy, useClass: HashLocationStrategy },InAppBrowser],
  exports: [],  
  bootstrap: [AppComponent]
})
export class AppModule {}
 