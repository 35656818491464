//api.service.ts
import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ValidateSessionService {

  constructor(private authService: AuthenticationService, public toastController: ToastController) { }

  private fooSubject = new Subject<any>();

    publishSomeData(data: any) {
        this.fooSubject.next(data);
        console.log('hizo algoooo');

        console.log(data);



      let date = new Date();
      let dateToken = this.createDateAsUTC(new Date(data.user.llat.date));
      //let expirationTime = decodedToken.tokenExpirationTime;

      let difference = date.getTime() - dateToken.getTime();
      let resultInDays = Math.round(difference / 86400000);
      
console.log('**********************************');
console.log(data.user.llat.date);
console.log(resultInDays);
console.log('**********************************');

      if(resultInDays>0){
        this.authService.logout();
        this.presentToast('Su ha expirado, por favor ingrese nuevamente');
      }
    }

    async presentToast(msg) {
      const toast = await this.toastController.create({
        message: msg,
        duration: 2000
      });
      toast.present();
    }

    createDateAsUTC(date) {
      let fechaUTC = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
      let datePanama = new Date(fechaUTC.getTime()+(18000000));
      return(datePanama);
    }

    getObservable(): Subject<any> {
        return this.fooSubject;
    }
}